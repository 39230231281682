import React, {ButtonHTMLAttributes, FC} from 'react';
import {useTranslation} from "react-i18next";
import {ReactSVG} from "react-svg";


interface Props extends ButtonHTMLAttributes<any> {
  children?: any;
  title?: string;
  href?: string;
  loading?: boolean;
  btnType?: 'primary'|'secondary'
  size?: 'lg';
  icon?: any;
  target?: string;
}

const Button: FC<Props> = ({
                             children,
                             title,
                             className,
                             type = 'button',
                             btnType = 'primary',
                             icon,
                             size,
                             loading,
                             href,
                             ...props
                           }) => {
  const {t} = useTranslation();
  const btnSizeClass = size ? ` btn-${size}` : '';
  const btnClassName = `btn btn-${btnType}${btnSizeClass}${loading ? ' loading' : ''} ${className || ''}`
  if (href) return (
    <a href={href} className={btnClassName} {...props}>
      {icon ? <ReactSVG src={icon} className={`react-icon btn__icon`}/> : null}
      <span>{t(title || '')}</span>
    </a>
  )
  return (
    <button
      {...props}
      type={type}
      className={btnClassName}
    >
      {icon ? <ReactSVG src={icon} className={`react-icon btn__icon`}/> : null}
      <span>{t(title || '')}</span>
    </button>
  );
}


export default Button;