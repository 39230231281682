import React, {FC} from 'react';
import Slider from 'react-slick';
import HomeProgramItem from "./HomeProgramItem";
import {programItems} from "./HomeSection2_Program";

interface Props {
}

const HomeProgramSlider: FC<Props> = () => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    variableWidth: true,
    centerPadding: '0px'
  };
  return (
    <div className='home__program_slider'>
      <Slider {...settings}>
        {programItems.map((icon, i) => (
          <HomeProgramItem icon={icon} idx={i} key={i}/>
        ))}
      </Slider>
    </div>
  );
}

export default HomeProgramSlider;