import React, {useState} from 'react';
import Header from "./components/Header";
import Footer from "./components/Footer";
import HomePage from "./pages/HomePage";
import {AuthModal} from "./modals";
import {TAuthType} from "./components/AuthForm/AuthForm";
import {types} from "sass";


function App() {
  const [modalVisible, setModalVisible] = useState<null | TAuthType>(null);

  const handleSignUp = (type?: TAuthType) => {
    setModalVisible(type || "register");
  }
  return (
    <>
      <Header handleSignUp={handleSignUp}/>
      <HomePage handleSignUp={handleSignUp}/>
      <Footer/>
      <AuthModal authType={modalVisible} open={Boolean(modalVisible)} onClose={() => setModalVisible(null)}/>
    </>
  );
}

export default App;
