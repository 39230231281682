import React, {FC} from 'react';
import logo from '../../assets/images/logo.png';
import Button from "../Button";
import {TAuthType} from "../AuthForm/AuthForm";
import {loginHref, registerHref} from "../../modules/api";

interface Props {
  handleSignUp: (type: TAuthType) => void;
}

const Header: FC<Props> = ({handleSignUp}) => {
  return (
    <header>
      <img src={logo} alt="logo" className='header__logo'/>
      <div className='d-flex gap-2'>
        <Button
          href={registerHref}
          target='_blank'
          // onClick={() => handleSignUp("register")}
          title='SIGN_UP'
        />
        <Button
          href={loginHref}
          target='_blank'
          // onClick={() => handleSignUp("login")}
          btnType='secondary'
          title='LOGIN'
        />
      </div>
    </header>
  );
}

export default Header;