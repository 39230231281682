import React, {FC, useState} from 'react';
import {useTranslation} from "react-i18next";
import plus from '../../../../assets/icons/plus.svg';
import minus from '../../../../assets/icons/minus.svg';
import {ReactSVG} from "react-svg";

interface Props {
}

const HomeSection4_FAQ: FC<Props> = () => {
  const {t} = useTranslation();
  const [activeIdx, setActiveIdx] = useState<null|number>(null);

  const toggle = (idx: number) => () => {
    setActiveIdx(prevState => {
      if (prevState === idx) return null;
      return idx;
    })
  }

  return (
    <section className='home__section_faq'>
      <h2 dangerouslySetInnerHTML={{__html: t('HOME_TITLE_4') || ''}}/>
      <div className='home__faq'>
        {Array(2).fill({}).map((_, i) => (
          <div className={`home__faq_item${activeIdx === i ? ' active' : ''}`} key={i}>
            <div className={`home__faq_item-wrap`}>
              <div className="home__faq_item-head" onClick={toggle(i)}>
                <div className='flex-grow-1 pe-4'>{t(`HOME_FAQ_${i + 2}_TITLE`)}</div>
                <ReactSVG src={activeIdx === i ? minus : plus} className='react-icon'/>
              </div>
              <div className="home__faq_item-content">
                {t(`HOME_FAQ_${i + 2}_TEXT`)}
              </div>
            </div>

          </div>
        ))}
      </div>
    </section>
  );
}

export default HomeSection4_FAQ;