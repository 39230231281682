import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import en from './en/affiliate.json';
import es from './es/affiliate.json';
import ru from './ru/affiliate.json';
import cs from './cs/affiliate.json';
import de from './de/affiliate.json';
import fr from './fr/affiliate.json';
import hu from './hu/affiliate.json';
import it from './it/affiliate.json';
import nl from './nl/affiliate.json';
import pl from './pl/affiliate.json';
import pt from './pt/affiliate.json';
import sv from './sv/affiliate.json';

enum ELanguage {
  English = 'en',
  Russian = 'ru',
  Spanish = 'es',
  German = 'de',
  French = 'fr',
  Italian = 'it',
  Dutch = 'nl',
  Portuguese = 'pt',
  Polish = 'pl',
  Czech = 'cs',
  Hungarian = 'hu',
  Swedish = 'sv',
}

export const availableLanguages: ELanguage[] = Object.values(ELanguage);

export const getLang = (ln?: ELanguage) => {
  //@ts-ignore
  if (!ln) ln = localStorage.getItem('ln') || ( window?.navigator?.userLanguage || window.navigator.language ).substring(0, 2);
  const rusList = ['ru', 'uk', 'kk', 'be', 'az', 'lv', 'ee', 'tr', 'ka', 'hy', 'uz', 'mo', 'bg'];
  if (rusList.includes(ln || '')) ln = ELanguage.Russian;
  ln = availableLanguages.includes(ln as ELanguage) ? ln : ELanguage.English;
  return ln;
}


const resources = {
  [ELanguage.English]: {
    translation: en,
  },
  [ELanguage.Russian]: {
    translation: ru,
  },
  [ELanguage.Spanish]: {
    translation: es,
  },
  [ELanguage.Czech]: {
    translation: cs,
  },
  [ELanguage.German]: {
    translation: de,
  },
  [ELanguage.French]: {
    translation: fr,
  },
  [ELanguage.Hungarian]: {
    translation: hu,
  },
  [ELanguage.Italian]: {
    translation: it,
  },
  [ELanguage.Dutch]: {
    translation: nl,
  },
  [ELanguage.Polish]: {
    translation: pl,
  },
  [ELanguage.Portuguese]: {
    translation: pt,
  },
  [ELanguage.Swedish]: {
    translation: sv,
  },

};


i18n
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: ELanguage.English,
    lng: getLang(),

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;