import React, {FC} from 'react';
import {Modal, ModalProps} from "react-responsive-modal";
import {ReactSVG} from "react-svg";
import close_svg from '../assets/icons/close.svg';
import AuthForm from "../components/AuthForm";
import {TAuthType} from "../components/AuthForm/AuthForm";

interface Props extends ModalProps {
  authType:TAuthType | null;
}
// showCloseIcon: false,
//   center: true,
//   classNames: {modalContainer: 'modal__bottom'}
const AuthModal: FC<Props> = ({authType, ...props}) => {
  return (
    <Modal animationDuration={0} showCloseIcon={false} center   classNames={{modalContainer: 'modal__bottom'}}  {...props} >
      <div className='modal__container'>
        <ReactSVG src={close_svg} className='modal-close' onClick={props.onClose}/>
        <AuthForm defaultAuthType={authType || "register"} title />
      </div>
    </Modal>
  );
}

export default AuthModal;