import React, {FC, useEffect, useState} from 'react';
import AuthSign from "./components/AuthSign";


const dataTitles: Record<TAuthType, string> = {
  'login': 'SIGN_IN',
  'register': 'CREATE_ACC',
};

export type TAuthType = 'login'|'register'
export type TTelegram = { code: string; url: string; qr: string };

interface Props {
  title?: boolean;
  defaultAuthType?: TAuthType;
}

const AuthForm: FC<Props> = ({defaultAuthType, title}) => {
  const [authType, setAuthType] = useState<TAuthType>(defaultAuthType || "register");

  useEffect(() => {
  }, []);

  const onSuccess = async (data: any) => {

  }

  return (
    <div className='w-100 position-relative'>
      <AuthSign
        title={title}
        onAuthTypeChange={setAuthType}
        authType={authType}
        onSuccess={onSuccess}
      />
    </div>
  );
}

export default AuthForm;