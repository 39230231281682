import React from 'react';
import ReactDOM from 'react-dom/client';
import './translates/i18n'
import {ToastContainer} from "react-toastify";
import {ReactSVG} from "react-svg";
import close_svg from './assets/icons/close.svg';

import 'react-toastify/dist/ReactToastify.css';
import './assets/styles/bootstrap.min.css'
import 'react-responsive-modal/styles.css';

import App from './App';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './assets/styles/constants.css'
import './assets/styles/main.scss'
import './assets/styles/media.scss'


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <>
    <App/>
    <ToastContainer
      icon={false}
      closeButton={<ReactSVG src={close_svg} className='react-icon'/>}
      closeOnClick
    />
  </>

);


