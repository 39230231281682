import React, {FC} from 'react';
import {useTranslation} from "react-i18next";
import check from '../../../../assets/icons/check.svg';
import arrow from '../../../../assets/icons/arrow-tr_accent.svg';
import {ReactSVG} from "react-svg";
import {registerHref} from "../../../../modules/api";

interface Props {
  onSignUp: () => void;
}

const HomeSection3_HowItWorks: FC<Props> = ({onSignUp}) => {
  const {t} = useTranslation();
  return (
    <section className='home__section_work '>
      <h2 dangerouslySetInnerHTML={{__html: t('HOME_TITLE_3') || ''}}/>
      <p className='text-muted'>{t('HOME_SUB_TITLE_3')}</p>
      <div className="home__work_container home__card_bg">
        <div className="home__work">
          {Array(3).fill({}).map((_, i) => (
            <div className='home__work_item' key={i}>
              <div className='home__work_step-container'>
                <div className='home__work_step'>{t('STEP')} {i + 1}</div>
                {i === 2 && <div className='home__work_step primary'>
                  <ReactSVG src={check} className='react-icon'/> {t('DONE')}
                </div>}
              </div>
              <h4>{t(`HOME_STEP_${i + 1}_TITLE`)}</h4>
              <p>{t(`HOME_STEP_${i + 1}_TEXT`)}</p>
              {i === 0 &&
                <a href={registerHref}
                   target='_blank'
                  // onClick={onSignUp}
                   className='d-flex align-items-center mt-2 cursor-pointer'>
                  <span className='text-accent me-1'>{t('SIGN_UP_NOW')}</span>
                  <ReactSVG src={arrow} className='react-icon'/>
                </a>
              }
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default HomeSection3_HowItWorks;