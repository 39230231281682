import React, {FC} from 'react';
import {useTranslation} from "react-i18next";
import {privacyHref, termsHref} from "../../modules/api";

interface Props {
}

const Footer: FC<Props> = () => {
  const {t} = useTranslation();
  return (
      <footer>
        <div className="home__footer">
          <div className='text-muted-14'>© 2024 Noodz. {t('FOOTER_COMP')}</div>
          <div className='home__footer-right'>
            <a href={privacyHref} target='_blank' className='text-muted-14'>{t('PRIVACY_POLICY')}</a>
            <a href={termsHref} target='_blank' className='text-muted-14'>{t('TERMS_OF_SERVICE')}</a>
          </div>
          {/*<div className='text-14'>*/}
          {/*  <span className='text-muted'>{t('COMPANY_C')} - </span><Link to={'/sitemap'}>{t('SITEMAP')}</Link>*/}
          {/*</div>*/}
        </div>
      </footer>
  );
}

export default Footer;