import React, {FC, useEffect, useState} from 'react';
import {Input, InputPassword} from "../../../components/FormControls";
import email_svg from "../../../assets/icons/email.svg";
import Button from "../../../components/Button";
import signIn_svg from "../../../assets/icons/signIn.svg";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {TAuthType} from "../AuthForm";
import {backendUrl} from "../../../modules/api";

interface Props {
  title?: boolean;
  onSuccess: (data: any) => void;
  authType: TAuthType;
  onAuthTypeChange: (type: TAuthType) => void;
}

const AuthSign: FC<Props> = ({onSuccess, authType, onAuthTypeChange, title}) => {
  const {t} = useTranslation();


  const [user, setUser] = useState({email: '', password: ''});
  const [loading, setLoading] = useState(false);

  const query = new URLSearchParams(window.location.search);
  const queryEmail = query.get('email');

  useEffect(() => {
    if (queryEmail) {
      setUser(prevState => ({...prevState, email: queryEmail}));
    }
  }, []);

  const handleChange = (key: string) => (e: any) => {
    setUser(prevState => ({...prevState, [key]: e.target.value}))
  }

  const handleLogin = async (e: any) => {
    e.preventDefault();
    try {
      setLoading(true);
      let res;
      if (authType === 'register') {
        const payload: any = {...user,agree: true, agree_use_info: true, repeat_password: user.password};
        const response = await fetch('https://noodz.affise.com/signup',{
          method: 'POST',
          headers: {
            accept: 'application/json',
          },
          body: JSON.stringify(payload)
        });
        const res = await response.json();
        console.log(123123);
        console.log(res);
      } else {
      }
      onSuccess(res);
    } catch (e: any) {
      toast.error(e)
    } finally {
      setLoading(false);
    }
  }

  const authTitle = authType === 'register' ? 'CREATE_ACC' : 'SIGN_IN';

  return (
    <>
      {title && <h2 className='page-title text-accent'>{t(authTitle)}</h2>}
      <form method='post' action='https://noodz.affise.com/v2/sign/up'>
        <Input
          required
          icon={email_svg}
          name='email'
          label='EMAIL'
          className='mb-3'
          placeholder='YOUR_EMAIL'
          value={user.email}
          type='email'
          onChange={handleChange('email')}
        />
        <InputPassword
          required
          value={user.password}
          onChange={handleChange('password')}
        />
        <input type="hidden" name='agree' value={'true'}/>
        <input type="hidden" name='agree_use_info' value={'true'}/>
        <input type="hidden" name='repeat_password' value={user.password}/>
        <Button
          size='lg'
          className='w-100 mt-4'
          icon={authType !== 'register' && signIn_svg}
          loading={loading}
          title={authTitle}
          type='submit'
        />
        <div className='mt-4 text-muted-14 text-center'>
          {authType === 'register'
            ?
            <span>{t('ALREADY_ACC')} <span onClick={() => onAuthTypeChange('login')}
                                           className='text-accent cursor-pointer'>{t('SIGN_IN')}</span></span>
            :
            authType === 'login' &&
            <span>{t('NO_ACC')} <span onClick={() => onAuthTypeChange('register')}
                                      className='text-accent cursor-pointer'>{t('SIGN_UP')}</span></span>
          }
        </div>
      </form>
    </>
  );
}

export default AuthSign;