import React, {FC} from 'react';
import {useTranslation} from "react-i18next";

interface Props {
  icon: any;
  idx: number
}

const HomeProgramItem:FC<Props> = ({icon, idx}): any => {
  const {t} = useTranslation();
  return (
    <div className='home__program_item-container'>
      <div className='home__program_item'>
        <img src={icon} alt="icon"/>
        <p>{t(`HOME_PROGRAM_${idx + 1}`)}</p>
      </div>
    </div>
  );
}

export default HomeProgramItem;