
export const backendUrl =  `https://api.noodz.ai`;

export const loginHref = 'https://noodz.affise.com/v2/sign/in';
export const registerHref = 'https://noodz.affise.com/v2/sign/up';
export const termsHref = 'https://noodz.affise.com/v2/terms';
export const privacyHref = 'https://noodz.affise.com/v2/privacy';



