import React, {FC} from 'react';
import {useTranslation} from "react-i18next";
import {ReactSVG} from "react-svg";
import arrow from '../../../../assets/icons/arrow-tr.svg';

import program_1_svg from '../../assets/icons/program_1.svg';
import program_1 from '../../assets/icons/program_1.png';
import program_2 from '../../assets/icons/program_2.png';
import program_3 from '../../assets/icons/program_3.png';
import program_4 from '../../assets/icons/program_4.png';
import HomeProgramItem from "./HomeProgramItem";
import HomeProgramSlider from "./HomeProgramSlider";
import {registerHref} from "../../../../modules/api";

export const programItems = [program_1, program_2, program_3, program_4]

interface Props {
  onSignUp: () => void;
}

const HomeSection2_Program: FC<Props> = ({onSignUp}) => {
  const {t} = useTranslation();
  return (
    <section className='home__section_program'>
      <h2 dangerouslySetInnerHTML={{__html: t('HOME_TITLE_2') || ''}}/>
      <p className='text-muted'>{t('HOME_SUB_TITLE_2')}</p>
      <div className="home__section_program-separator"/>
      <div className='home__section_footer'>
        <a className="home__program_btn" href={registerHref}
           target='_blank'
          // onClick={onSignUp}
        >
          <div className="home__program_btn-bg"/>
          <div className='position-relative'>{t('TRY_IT')}</div>
          <ReactSVG src={arrow} className='react-icon ms-2 position-relative'/>
        </a>
        <div className='home__program_items'>
          {programItems.map((icon, i) => (
            <HomeProgramItem icon={icon} idx={i} key={i}/>
          ))}
        </div>
        <HomeProgramSlider/>
      </div>

    </section>
  );
}

export default HomeSection2_Program;